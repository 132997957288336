export const environment = {
    production: true,
    hmr: false,
    isDas: false,
    isBelfius: true,
    isDvv: false,
    apiUrl: 'https://extranetpro.das.be', // 'https://extranetprotest.das.be',
    createContractUrl: 'https://extranet-belfius.das.be/redirect/newcontract',
    // 'https://extranet-belfius-testint.das.be/redirect/newcontract',
    extranetUrl: 'https://extranet-belfius.das.be/redirect/homepage',
    // 'https://extranet-belfius-testint.das.be/redirect/homepage'
}
